.App {
  text-align: center;
}

#bgvideo {
  object-fit: cover;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0px;
  left: 0;
}